import PropTypes from "prop-types";
import React from "react";

import classNames from "classnames";

const DivFactory = (props) => {
  const {
    displayName,
    cssClass
  } = props;

  return class extends React.Component {
    static displayName = displayName;

    static propTypes = {
      children: PropTypes.node,
      variants: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
      ])
    };

    render() {
      const {
        children,
        variants,
        ...other
      } = this.props;

      return (
        <div className={classNames(cssClass, variants)} {...other}>
          {children}
        </div>
      );
    }
  };
};

export default DivFactory;
