import PropTypes from "prop-types";
import React from "react";
import { inject, observer } from "mobx-react";
import history from "../history.js";

import I18n from "i18n-js";

import ResourceView from "../components/ResourceView.js";
import OrganizationNamesList from "../components/OrganizationNamesList.js";

const OrganizationNamesListObserver = observer(OrganizationNamesList);

class OrganizationNamesListContainer extends React.Component {
  static propTypes = {
    wordsStore: PropTypes.object,
    wordStore: PropTypes.object,
    location: PropTypes.object
  };

  handleDecline = (word) => {
    this.props.wordsStore.updateIsAllowed(word, "false");
  };

  handleApprove = (word) => {
    this.props.wordsStore.updateIsAllowed(word, "true");
  };

  handleDelete = (word) => {
    if (!confirm(I18n.t("components.OrganizationNamesList.confirm_organization_opinion"))) {
      return;
    }

    this.props.wordStore.destroyWord(word.id).then(() => {
      const {
        location
      } = this.props;

      history.push(location);
    });
  };

  handleAlternativeApprove = (word, alternative) => {
    this.props.wordStore.updateAlternativeIsAllowed(word, alternative, "true");
  };

  handleAlternativeDecline = (word, alternative) => {
    this.props.wordStore.updateAlternativeIsAllowed(word, alternative, "false");
  };

  handleAlternativeDelete = (word, alternative) => {
    this.props.wordStore.updateAlternativeIsActive(word, alternative, "false");
  };

  generatePaginationLink = (page) => {
    const {
      pathname,
      query
    } = this.props.location;

    return {
      pathname,
      query: {
        ...query,
        page: page
      }
    };
  };

  render() {
    const {
      wordsStore
    } = this.props;
    const {
      words,
      pagination
    } = wordsStore.words.value();

    return (
      <OrganizationNamesListObserver words={words}
        pagination={pagination}
        paginationLinkProps={{generateLink: this.generatePaginationLink}}
        onApprove={this.handleApprove}
        onDecline={this.handleDecline}
        onActive={this.handleDelete}
        onAlternativeApprove={this.handleAlternativeApprove}
        onAlternativeDecline={this.handleAlternativeDecline}
        onAlternativeDelete={this.handleAlternativeDelete} />
    );
  }
}

export default inject("wordsStore", "wordStore")(ResourceView((props) => props.wordsStore.words)(observer(OrganizationNamesListContainer)));
