import PropTypes from "prop-types";
import React from "react";
import I18n from "i18n-js";

import { observer, inject } from "mobx-react";

import FormFieldInformationDisplay from "./FormFieldInformationDisplay.js";

class CorrectnessInformationForm extends React.Component {
  static propTypes = {
    word: PropTypes.shape({
      name: PropTypes.string.isRequired,
      subCategory: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      isAllowed: PropTypes.string.isRequired
    })
  };

  updateProperty = (key, value) => {
    this.props.word[key] = value;
  };

  onChange = (event) => {
    this.updateProperty(event.target.name, event.target.value);
  };

  render() {
    const {
      word,
      categoriesStore
    } = this.props;
    const {
      categories
    } = categoriesStore.categories.valueOrInitial();

    const subCategoryOptions = [];
    categories.forEach((category) => {
      subCategoryOptions.push(
        <option value={category.id} key={category.id}>{category.displayName}</option>
      );
    });

    return (
      <div className="information-form">
        <FormFieldInformationDisplay label={I18n.t("correctness_words.detail.name")}>
          <input type="text" name="name" value={word.name} onChange={this.onChange} />
        </FormFieldInformationDisplay>
        <FormFieldInformationDisplay label={I18n.t("correctness_words.detail.category")}>
          <select name="subCategory" value={word.subCategory} onChange={this.onChange}>
            {subCategoryOptions}
          </select>
        </FormFieldInformationDisplay>
        <FormFieldInformationDisplay label={I18n.t("correctness_words.detail.is_allowed")}>
          <select value={word.isAllowed} name="isAllowed" onChange={this.onChange}>
            <option value="true">{I18n.t("correctness_words.search.is_allowed.true")}</option>
            <option value="false">{I18n.t("correctness_words.search.is_allowed.false")}</option>
          </select>
        </FormFieldInformationDisplay>
      </div>
    );
  }
}

export default inject("categoriesStore")(observer(CorrectnessInformationForm));
