import PropTypes from "prop-types";
import React from "react";

class ApproveAction extends React.Component {
  static propTypes = {
    tooltip: PropTypes.string,
    opinionsAmount: PropTypes.number,
    onClick: PropTypes.func.isRequired
  };

  handleClick = (event) => {
    event.preventDefault();

    this.props.onClick();
  };

  render() {
    return (
      <div className="opinion-approve-action" onClick={this.handleClick} title={this.props.tooltip}>
        {this.props.opinionsAmount || ""}
      </div>
    );
  }
}

export default ApproveAction;
