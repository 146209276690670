import PropTypes from "prop-types";
import React from "react";

import { propTypes as MobxPropTypes } from "mobx-react";

import { Link } from "react-router";

import I18n from "i18n-js";

import ContentContainer from "guc/components/ContentContainer";
import TitleBlock from "guc/components/TitleBlock";
import AlternativesList from "./AlternativesList.js";
import TwoColumns from "./TwoColumns.js";
import CorrectnessWordInformationAttributes from "./CorrectnessWordInformationAttributes.js";

class CorrectnessDetail extends React.Component {
  static propTypes = {
    word: MobxPropTypes.observableObject.isRequired,
    editPath: PropTypes.string,
    newPath: PropTypes.string
  };

  render() {
    const {
      word,
      editPath,
      newPath
    } = this.props;

    return (
      <div className="word-detail">
        <ContentContainer>
          <TitleBlock>
            {word.name}
            <Link to={editPath} title={I18n.t("actions.titles.editCorrectnessWord")}>
              <div className="guc-edit-action" />
            </Link>
            <Link to={newPath} title={I18n.t("actions.titles.newCorrectnessWord")}>
              <div className="guc-add-action" />
            </Link>
          </TitleBlock>

          <TwoColumns>
            <CorrectnessWordInformationAttributes word={word} />
            <AlternativesList alternatives={word.alternatives}
              title={I18n.t("components.CorrectnessForm.alternatives.title")}
              listTitle={I18n.t("components.CorrectnessForm.alternatives.list_title")}  />
          </TwoColumns>
        </ContentContainer>
      </div>
    );
  }
}

export default CorrectnessDetail;
