import Crossing from "crossing";

const routingTable = {
  difficultWords: "/difficult_words",
  showDifficultWord: "/difficult_words/show/:wordId",
  editDifficultWord: "/difficult_words/edit/:wordId",
  newDifficultWord: "/difficult_words/new",
  correctnessWords: "/correctness_words",
  showCorrectnessWord: "/correctness_words/show/:wordId",
  editCorrectnessWord: "/correctness_words/edit/:wordId",
  newCorrectnessWord: "/correctness_words/new",
  organizationNames: "/organization_names",
  showOrganizationName: "/organization_names/show/:wordId",
  editOrganizationName: "/organization_names/edit/:wordId",
  newOrganizationName: "/organization_names/new"
};

const routes = new Crossing(new RegExp(":([A-Za-z0-9-_%]{1,})"));
routes.load(routingTable);

export { routes as default, routingTable };
