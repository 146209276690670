import PropTypes from "prop-types";
import React from "react";
import { PropTypes as MobxPropTypes } from "mobx-react";
import I18n from "i18n-js";

import TableHeader from "guc/components/TableHeader";
import TableBlock from "guc/components/TableBlock";
import TableContent from "guc/components/TableContent";

import WordsTableRow from "./WordsTableRow.js";

class WordsTable extends React.Component {
  static propTypes = {
    words: MobxPropTypes.observableArray,
    onApprove: PropTypes.func,
    onDecline: PropTypes.func
  };

  render() {
    return (
      <TableBlock>
        <TableHeader fields={[
            I18n.t("difficult_words.list_headers.name"),
            I18n.t("difficult_words.list_headers.category"),
            I18n.t("difficult_words.list_headers.alternatives"),
            ""
        ]} />
        <TableContent>
          {this.props.words.map((word) =>
            <WordsTableRow key={word.id} word={word} onApprove={this.props.onApprove} onDecline={this.props.onDecline} />
          )}
        </TableContent>
      </TableBlock>
    );
  }
}

export default WordsTable;
