import PropTypes from "prop-types";
import React from "react";
import I18n from "i18n-js";

import DevTools from "mobx-react-devtools";

import PageContent from "../components/PageContent.js";
import MainContent from "../components/MainContent.js";
import SidebarContent from "../components/SidebarContent.js";
import NavigationSidebar from "./NavigationSidebar.js";
import SiteContent from "guc/components/SiteContent";
import HeaderBlock from "guc/components/HeaderBlock";
import UserAccount from "guc/components/UserAccount";
import KTFooterBlock from "../components/KTFooterBlock";

class App extends React.Component {
  static propTypes = {
    children: PropTypes.node
  };

  constructor(props) {
    super(props);
    const mainDiv = document.getElementById("main");
    const username = mainDiv.dataset.username;

    const categories = JSON.parse(mainDiv.dataset.categories);

    this.state = {
      categories,
      username
    };
  }

  render() {
    return (
      <PageContent>
        {process.env.NODE_ENV !== "production" ? <DevTools /> : null}
        <NavigationSidebar categories={this.state.categories} />
        <SiteContent>
          <HeaderBlock>
            <UserAccount userName={this.state.username}
              links={[{href: "/users/sign_out", label: I18n.t("user_account.log_out")}]}/>
          </HeaderBlock>
          <MainContent>
            {this.props.children}
          </MainContent>
          <SidebarContent>
            {this.props.sidebar}
          </SidebarContent>
        </SiteContent>
        <KTFooterBlock variants="-sticky"
          initialYear="2016"/>
      </PageContent>
    );
  }
}

export default App;
