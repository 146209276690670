import { deserialize, update } from "serializr";

import Word from "../models/Word.js";
import Resource from "../models/Resource.js";

const initialWord = {};

class WordStore {
  word = new Resource(null, initialWord)

  constructor(wordsTransport) {
    this.wordsTransport = wordsTransport;
  }

  loadWord(id) {
    this.word.refresh(
      this.wordsTransport.loadWord(id).then((word) =>
        deserialize(Word, word)
      )
    );
  }

  newWord(subCategoryPromise, mainCategory = "readability") {
    this.word.refresh(
      subCategoryPromise.then((subCategory) =>
        deserialize(Word, {
          mainCategory: mainCategory,
          isAllowed: "false",
          subCategory
        })
      )
    );
  }

  createWord(word) {
    return this.word.refresh(
      this.wordsTransport.createWord(word).then((newWord) => {
        update(word, newWord);
        return word;
      })
    );
  }

  updateWord(word) {
    return this.word.refresh(
      this.wordsTransport.updateWord(word).then((newWord) => {
        update(word, newWord);
        return word;
      })
    );
  }

  destroyWord(id) {
    return this.word.refresh(
      this.wordsTransport.destroyWord(id).then((word) =>
        deserialize(Word, word)
      )
    );
  }

  destroySubmittedWord(id) {
    return this.word.refresh(
      this.wordsTransport.destroySubmittedWord(id).then((word) =>
        deserialize(Word, word)
      )
    );
  }

  updateAlternativeIsAllowed(word, alternative, isAllowed) {
    return this.word.refresh(
      this.wordsTransport.updateAlternativeIsAllowed(word, alternative, isAllowed).then((updatedAlternative) => {
        update(alternative, updatedAlternative);

        return alternative;
      })
    );
  }

  updateAlternativeIsActive(word, alternative, isActive) {
    return this.word.refresh(
      this.wordsTransport.updateAlternativeIsActive(word, alternative, isActive).then((updatedAlternative) => {
        update(alternative, updatedAlternative);

        return alternative;
      })
    );
  }
}

export default WordStore;
