import PropTypes from "prop-types";
import React from "react";
import { observer, inject, propTypes } from "mobx-react";

import { Link } from "react-router";

import classNames from "classnames";

import I18n from "i18n-js";

import routes from "../routes.js";

import OpinionsList from "./OpinionsList.js";

class OrganizationNamesTableRow extends React.Component {
  static propTypes = {
    word: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      alternatives: propTypes.arrayOrObservableArray
    }),
    onApprove: PropTypes.func.isRequired,
    onDecline: PropTypes.func.isRequired,
    onActive: PropTypes.func.isRequired,
    onAlternativeApprove: PropTypes.func.isRequired,
    onAlternativeDecline: PropTypes.func.isRequired,
    onAlternativeDelete: PropTypes.func.isRequired
  };

  handleApprove = () => {
    this.props.onApprove(this.props.word);
  };

  handleDecline = () => {
    this.props.onDecline(this.props.word);
  };

  handleDelete = () => {
    this.props.onActive(this.props.word);
  };

  handleAlternativeApprove = (alternative) => {
    this.props.onAlternativeApprove(this.props.word, alternative);
  };

  handleAlternativeDecline = (alternative) => {
    this.props.onAlternativeDecline(this.props.word, alternative);
  };

  handleAlternativeDelete = (alternative) => {
    this.props.onAlternativeDelete(this.props.word, alternative);
  };

  render() {
    const {
      word
    } = this.props;

    const cssVariants = classNames({
      simple: word.isSimple,
      difficult: word.isDifficult,
      inactive: !word.isCurrentlyActive
    });

    const fields = [ word.name, "" ];
    const actions = [
      <Link key="showOrganizationName" to={routes.get("showOrganizationName", { wordId: word.id })} title={I18n.t("actions.titles.showOrganizationWord")}><div className="guc-view-action" /></Link>,
      <Link key="editOrganizationName" to={routes.get("editOrganizationName", { wordId: word.id })} title={I18n.t("actions.titles.editOrganizationWord")}><div className="guc-edit-action" /></Link>
    ];

    return (
      <div className="organization-word">
        <OpinionsList wordId={word.id}
          variants={cssVariants}
          opinions={word.opinions}
          fields={fields}
          actions={actions}
          onApprove={this.handleApprove}
          onApproveTooltip={I18n.t("components.OrganizationNamesTableRow.opinions.toolips.approve")}
          onDecline={this.handleDecline}
          onDeclineToolTip={I18n.t("components.OrganizationNamesTableRow.opinions.toolips.decline")}
          onActive={this.handleDelete}
          onActiveTooltip={I18n.t("components.OrganizationNamesTableRow.opinions.toolips.delete", { word: word.name })}/>

        { word.alternatives.map((alternative) => {
          const alternativeCssClass = classNames({
            "-simple-alternative": alternative.isSimple,
            "-difficult-alternative": alternative.isDifficult,
            "-inactive-alternative": !alternative.isCurrentlyActive
          });

          return (
            <OpinionsList key={alternative.id}
              wordId={word.id}
              variants={alternativeCssClass}
              opinions={alternative.opinions}
              fields={["", alternative.name]}
              actions={[<span key="0" className="empty-icon" />, <span key="1" className="empty-icon" />]}
              onApprove={this.handleAlternativeApprove.bind(this, alternative)}
              onApproveTooltip={I18n.t("components.OrganizationNamesTableRow.opinions.toolips.alternativeApprove")}
              onDecline={this.handleAlternativeDecline.bind(this, alternative)}
              onDeclineToolTip={I18n.t("components.OrganizationNamesTableRow.opinions.toolips.alternativeDecline")}
              onActive={this.handleAlternativeDelete.bind(this, alternative)}
              onActiveTooltip={I18n.t("components.OrganizationNamesTableRow.opinions.toolips.alternativeDelete")}/>
          );
        })}
      </div>
    );
  }
}

export default inject("categoriesStore")(observer(OrganizationNamesTableRow));
