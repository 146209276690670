import React from "react";
import { inject, observer } from "mobx-react";
import { IndexRoute, Router, Route } from "react-router";

import { routingTable } from "../routes.js";

import App from "./App.js";
import DifficultWordsList from "./DifficultWordsList.js";
import WordDetail from "./WordDetail.js";
import WordEdit from "./WordEdit.js";
import WordNew from "./WordNew.js";

import CorrectnessWordsList from "./CorrectnessWordsList.js";
import CorrectnessDetail from "./CorrectnessDetail.js";
import CorrectnessNew from "./CorrectnessNew.js";
import CorrectnessEdit from "./CorrectnessEdit.js";

import OrganizationNamesList from "./OrganizationNamesList.js";
import OrganizationNameDetail from "./OrganizationNameDetail.js";
import OrganizationNameNew from "./OrganizationNameNew.js";
import OrganizationNameEdit from "./OrganizationNameEdit.js";

import CorrectnessWordsSearchBlock from "./CorrectnessWordsSearchBlock.js";
import DifficultWordsSearchBlock from "./DifficultWordsSearchBlock.js";
import OrganizationNamesSearchBlock from "./OrganizationNamesSearchBlock.js";

const onEnter = function(nextState, _replace) {
  this.viewStore.navigatedTo(this.name, nextState);
};

const onChange = function(_prevState, nextState, _replace) {
  this.viewStore.navigatedTo(this.name, nextState);
};

class RouterComponent extends React.Component {
  render() {
    const viewStore = this.props.viewStore;

    return (
      <Router history={this.props.history}>
        <Route path="/" component={App}>
          <Route components={{sidebar: DifficultWordsSearchBlock, children: (props) => props.children}}>
            <IndexRoute component={DifficultWordsList} name="difficultWords" onEnter={onEnter} onChange={onChange} viewStore={viewStore} />
            <Route path={routingTable.difficultWords} component={DifficultWordsList} name="difficultWords" onEnter={onEnter} onChange={onChange} viewStore={viewStore} />
            <Route path={routingTable.showDifficultWord} component={WordDetail} name="showDifficultWord" onEnter={onEnter} onChange={onChange} viewStore={viewStore} />
            <Route path={routingTable.editDifficultWord} component={WordEdit} name="editDifficultWord" onEnter={onEnter} onChange={onChange} viewStore={viewStore} />
            <Route path={routingTable.newDifficultWord} component={WordNew} name="newDifficultWord" onEnter={onEnter} onChange={onChange} viewStore={viewStore} />
          </Route>
          <Route components={{sidebar: CorrectnessWordsSearchBlock, children: (props) => props.children}}>
            <Route path={routingTable.correctnessWords} component={CorrectnessWordsList} name="correctnessWords" onEnter={onEnter} onChange={onChange} viewStore={viewStore} />
            <Route path={routingTable.showCorrectnessWord} component={CorrectnessDetail} name="showCorrectnessWord" onEnter={onEnter} onChange={onChange} viewStore={viewStore} />
            <Route path={routingTable.editCorrectnessWord} component={CorrectnessEdit} name="editCorrectnessWord" onEnter={onEnter} onChange={onChange} viewStore={viewStore} />
            <Route path={routingTable.newCorrectnessWord} component={CorrectnessNew} name="newCorrectnessWord" onEnter={onEnter} onChange={onChange} viewStore={viewStore} />
          </Route>
          <Route components={{sidebar: OrganizationNamesSearchBlock, children: (props) => props.children}}>
            <Route path={routingTable.organizationNames} component={OrganizationNamesList} name="organizationNames" onEnter={onEnter} onChange={onChange} viewStore={viewStore} />
            <Route path={routingTable.showOrganizationName} component={OrganizationNameDetail} name="showOrganizationName" onEnter={onEnter} onChange={onChange} viewStore={viewStore} />
            <Route path={routingTable.editOrganizationName} component={OrganizationNameEdit} name="editOrganizationName" onEnter={onEnter} onChange={onChange} viewStore={viewStore} />
            <Route path={routingTable.newOrganizationName} component={OrganizationNameNew} name="newOrganizationName" onEnter={onEnter} onChange={onChange} viewStore={viewStore} />
          </Route>
        </Route>
      </Router>
    );
  }
}

export default inject("viewStore")(observer(RouterComponent));
