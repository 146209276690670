import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router";
import I18n from "i18n-js";

class SaveCancel extends React.Component {
  static propTypes = {
    cancelPath: PropTypes.string.isRequired
  };

  render() {
    return (
      <div className="button-group">
        <button className="button-element" type="submit">
          {I18n.t("actions.save")}
        </button>
        <Link to={this.props.cancelPath} className="button-element hyperlink-element">
          {I18n.t("actions.cancel")}
        </Link>
      </div>
    );
  }
}

export default SaveCancel;
