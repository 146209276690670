import PropTypes from "prop-types";
import React from "react";
import I18n from "i18n-js";

import ContentContainer from "guc/components/ContentContainer";
import TitleBlock from "guc/components/TitleBlock";

import RadioButtonList from "./RadioButtonList.js";
import InputField from "./InputField.js";
import FormFieldInformationDisplay from "./FormFieldInformationDisplay.js";

class CorrectnessSearchBlock extends React.Component {
  static propTypes = {
    onSearch: PropTypes.func.isRequired,
    isAllowed: PropTypes.string,
    name: PropTypes.string
  };

  defaultStateFromProps = (props) => {
    return {
      submitted: props.submitted || "all",
      proposerOpinions: props.proposerOpinions || "all",
      isAllowed: props.isAllowed || "all",
      name: props.name || ""
    };
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState(this.defaultStateFromProps(nextProps));
  }

  handleChange = (key, value) => {
    this.setState({
      [key]: value
    });
  };

  handleSearch = (event) => {
    event.preventDefault();

    const search = {};
    if(this.state.submitted !== "all") {
      search.submitted = this.state.submitted;
    }

    if(this.state.proposerOpinions !== "all") {
      search.proposerOpinions = this.state.proposerOpinions;
    }

    if(this.state.isAllowed !== "all") {
      search.isAllowed = this.state.isAllowed;
    }

    if(this.state.name !== "") {
      search.name = this.state.name;
    }
    this.props.onSearch(search);
  };

  handleClear = (event) => {
    event.preventDefault();
    this.setState(this.defaultStateFromProps({}));
  };

  state = this.defaultStateFromProps(this.props);

  render() {
    const submittedProps = {
      title: I18n.t("difficult_words.search.status.title"),
      name: "submitted",
      value: this.state.submitted,
      onChange: this.handleChange,
      options: [
        {
          text: I18n.t("difficult_words.search.status.all"),
          value: "all"
        },
        {
          text: I18n.t("difficult_words.search.status.submitted"),
          value: "true"
        }
      ]
    };

    const editorialStaffOpinions = {
      title: I18n.t("correctness_words.search.opinion_editorial_staff.title"),
      name: "isAllowed",
      value: this.state.isAllowed,
      onChange: this.handleChange,
      options: [
        {
          text: I18n.t("correctness_words.search.opinion_editorial_staff.all"),
          value: "all"
        },
        {
          text: I18n.t("correctness_words.search.opinion_editorial_staff.true"),
          value: "true"
        },
        {
          text: I18n.t("correctness_words.search.opinion_editorial_staff.false"),
          value: "false"
        },
        {
          text: I18n.t("correctness_words.search.opinion_editorial_staff.none"),
          value: "unknown"
        }
      ]
    };

    const proposerOpinions = {
      title: I18n.t("correctness_words.search.proposer_opinions.title"),
      name: "proposerOpinions",
      value: this.state.proposerOpinions,
      onChange: this.handleChange,
      options: [
        {
          text: I18n.t("correctness_words.search.proposer_opinions.all"),
          value: "all"
        },
        {
          text: I18n.t("correctness_words.search.proposer_opinions.true"),
          value: "true"
        },
        {
          text: I18n.t("correctness_words.search.proposer_opinions.false"),
          value: "false"
        },
        {
          text: I18n.t("correctness_words.search.proposer_opinions.none"),
          value: "unknown"
        }
      ]
    };

    return (
      <ContentContainer>
        <TitleBlock>{I18n.t("correctness_words.search.title")}</TitleBlock>
        <div className="search-block form-container">
          <form onSubmit={this.handleSearch}>
            <FormFieldInformationDisplay label={I18n.t("correctness_words.search.word.title")} variants="-stacked">
              <InputField name="name"
                value={this.state.name}
                onChange={this.handleChange} />
            </FormFieldInformationDisplay>

            <RadioButtonList {...submittedProps} />

            <RadioButtonList {...editorialStaffOpinions} />

            <span className="disabled-block">
              <RadioButtonList {...proposerOpinions} disabled={true}/>
            </span>

            <div className="button-group">
              <button type="submit">
                {I18n.t("correctness_words.search.buttons.search")}
              </button>
              <button onClick={this.handleClear}>
                {I18n.t("correctness_words.search.buttons.clear")}
              </button>
            </div>
          </form>
        </div>
      </ContentContainer>
    );
  }
}

export default CorrectnessSearchBlock;
