import PropTypes from "prop-types";
import React from "react";
import { observer, inject, propTypes } from "mobx-react";

import { Link } from "react-router";

import I18n from "i18n-js";

import classNames from "classnames";

import routes from "../routes.js";

import OpinionsList from "./OpinionsList.js";

class CorrectnessTableRow extends React.Component {
  static propTypes = {
    word: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      alternatives: propTypes.arrayOrObservableArray
    }),
    onApprove: PropTypes.func.isRequired,
    onDecline: PropTypes.func.isRequired
  };

  handleApprove = () => {
    this.props.onApprove(this.props.word);
  };

  handleDecline = () => {
    this.props.onDecline(this.props.word);
  };

  render() {
    const {
      word
    } = this.props;

    const cssVariants = classNames({
      simple: word.isSimple,
      difficult: word.isDifficult
    });

    const fields = [
      word.name,
      word.alternatives.map((alternative) => alternative.name).join(",")
    ];
    const actions = [
      <Link key="showCorrectnessWord" to={routes.get("showCorrectnessWord", { wordId: word.id })} title={I18n.t("actions.titles.showCorrectnessWord")}><div className="guc-view-action" /></Link>,
      <Link key="editCorrectnessWord" to={routes.get("editCorrectnessWord", { wordId: word.id })} title={I18n.t("actions.titles.editCorrectnessWord")}><div className="guc-edit-action" /></Link>
    ];

    return (
      <OpinionsList wordId={word.id}
        variants={cssVariants}
        opinions={word.opinions}
        fields={fields}
        actions={actions}
        onApprove={this.handleApprove}
        onApproveTooltip={I18n.t("components.CorrectnessTableRow.toolips.approve")}
        onDecline={this.handleDecline}
        onDeclineToolTip={I18n.t("components.CorrectnessTableRow.toolips.decline")}/>
    );
  }
}

export default inject("categoriesStore")(observer(CorrectnessTableRow));
