import PropTypes from "prop-types";
import React from "react";

import TooltipIcon from "./TooltipIcon.js";

class RadioButtonList extends React.Component {
  static propTypes = {
    options: PropTypes.array,
    title: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    tooltip: PropTypes.string
  };

  handleChange = (event) => {
    this.props.onChange(event.target.name, event.target.value);
  };

  render() {
    const {
      title,
      options,
      name,
      value,
      disabled,
      tooltip
    } = this.props;

    return (
      <div>
        <div className="label-block">
          {title}
          { tooltip &&
            <TooltipIcon tooltip={tooltip} />
          }
        </div>
        <ol className="unordered-list -no-style">
          {options.map((option) =>
            <li className="list-element" key={option.value}>
              <label>
                <input
                  type="radio"
                  className="radio-button"
                  name={name}
                  checked={value === option.value}
                  value={option.value}
                  onChange={this.handleChange}
                  disabled={disabled} />
                {option.text}
              </label>
            </li>
          )}
        </ol>
      </div>
    );
  }
}

export default RadioButtonList;
