import PropTypes from "prop-types";
import React from "react";

class MainContent extends React.Component {
  static propTypes = {
    children: PropTypes.node
  };

  render() {
    return (
      <div className="main-content">
        {this.props.children}
      </div>
    );
  }
}

export default MainContent;
