import React from "react";

import { Link } from "react-router";
import I18n from "i18n-js";

import { propTypes as MobxPropTypes } from "mobx-react";

import ContentContainer from "guc/components/ContentContainer";
import TitleBlock from "guc/components/TitleBlock";
import AlternativesList from "./AlternativesList.js";
import TwoColumns from "./TwoColumns.js";
import WordInformationAttributes from "./WordInformationAttributes.js";

import routes from "../routes.js";

class WordDetail extends React.Component {
  static propTypes = {
    word: MobxPropTypes.observableObject.isRequired
  };

  render() {
    const word = this.props.word;

    return (
      <div className="word-detail">
        <ContentContainer>
          <TitleBlock>
            {word.name}
            <Link to={routes.get("editDifficultWord", {wordId: word.id})} title={I18n.t("actions.titles.editDifficultWord")}>
              <div className="guc-edit-action" />
            </Link>
            <Link to={routes.get("newDifficultWord")} title={I18n.t("actions.titles.newDifficultWord")}>
              <div className="guc-add-action" />
            </Link>
          </TitleBlock>

          <TwoColumns>
            <WordInformationAttributes word={word} />
            <AlternativesList alternatives={word.alternatives} />
          </TwoColumns>
        </ContentContainer>
      </div>
    );
  }
}

export default WordDetail;
