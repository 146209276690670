import PropTypes from "prop-types";
import React from "react";

import classNames from "classnames";

import NavigationSubItem from "guc/components/NavigationSubItem";

class UserAccount extends React.Component {
  static propTypes = {
    userName: PropTypes.string,
    links: PropTypes.arrayOf(
      PropTypes.shape({
        href: PropTypes.string,
        label: PropTypes.string
      })
    )
  };

  static defaultProps = {
    links: []
  };

  state = {
    expand: false
  };

  expandMenu = (_event) => {
    this.setState({expand: !this.state.expand});
  };

  menuClass = () => {
    if(this.state.expand) {
      return "guc-account-menu active";
    } else {
      return "guc-account-menu";
    }
  };

  accountClass = () => {
    if(this.state.expand) {
      return "account active";
    } else {
      return "account";
    }
  };

  render() {
    const accountCssClass = classNames("account", {"active": this.state.expand});
    const accountMenuCssClass = classNames("guc-account-menu", {"active": this.state.expand});

    return (
      <div className="guc-user-account">
        <div className={accountCssClass} onClick={this.expandMenu}>
          <i className="icon fa-user"/>
          {this.props.userName}
          <ul className={accountMenuCssClass}>
            {this.props.links.map((item, i) =>
              <NavigationSubItem {...item} key={i} />
            )}
          </ul>
        </div>
      </div>
    );
  }
}

export default UserAccount;
