import PropTypes from "prop-types";
import React from "react";
import { PropTypes as MobxPropTypes } from "mobx-react";

import { Link } from "react-router";

import I18n from "i18n-js";

import routes from "../routes.js";

import ContentContainer from "guc/components/ContentContainer";
import TitleBlock from "guc/components/TitleBlock";

import PaginationBlock from "./PaginationBlock.js";
import OrganizationNamesTable from "./OrganizationNamesTable.js";
import ListDisplay from "./ListDisplay.js";

class OrganizationNamesList extends React.Component {
  static propTypes = {
    words: MobxPropTypes.observableArray,
    pagination: PropTypes.shape({
      page: PropTypes.number,
      totalPages: PropTypes.number
    }),
    paginationLinkProps: PropTypes.object
  };

  render() {
    const {
      words,
      pagination,
      paginationLinkProps,
      ...other
    } = this.props;

    const title = I18n.t("organization_names.title");

    return (
      <div className="organization-names-list">
        <ContentContainer>
          <TitleBlock>
            {title}
            <Link to={routes.get("newOrganizationName")} title={I18n.t("actions.titles.newOrganizationWord")}>
              <div className="guc-add-action" />
            </Link>
          </TitleBlock>

          <ListDisplay hasData={words.length > 0}>
            <div>
              <OrganizationNamesTable words={words} {...other} />

              <PaginationBlock {...pagination} linkProps={paginationLinkProps} />
            </div>
          </ListDisplay>

        </ContentContainer>
      </div>
    );
  }
}

export default OrganizationNamesList;
