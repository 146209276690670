import { deserialize, createSimpleSchema, list, object } from "serializr";

import Category from "../models/Category.js";
import Resource from "../models/Resource.js";

const loadCategoriesSchema = createSimpleSchema({
  categories: list(object(Category))
});

const initialCategories = {
  categories: []
};

class CategoriesStore {
  categories = new Resource(null, deserialize(loadCategoriesSchema, initialCategories))

  constructor(categoriesTransport) {
    this.categoriesTransport = categoriesTransport;
  }

  refresh(filter = {}) {
    this.categories.refresh(
      this.categoriesTransport.loadCategories(filter).then(({categories}) =>
        deserialize(loadCategoriesSchema, {categories})
      ), deserialize(loadCategoriesSchema, initialCategories)
    );
  }

  displayForSubCategory(subCategory) {
    if(this.categories.state() !== "fulfilled") {
      return "";
    }

    const {
      categories
    } = this.categories.value();

    const category = categories.find((item) => item.id === subCategory);
    if(category) {
      return category.displayName;
    }
    return subCategory;
  }
}

export default CategoriesStore;
