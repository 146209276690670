import React from "react";
import { propTypes as MobxPropTypes } from "mobx-react";

import routes from "../routes.js";

import CorrectnessWordDetail from "./CorrectnessWordDetail.js";

const CorrectnessDetail = (props) =>
  <CorrectnessWordDetail {...props}
    newPath={routes.get("newCorrectnessWord")}
    editPath={routes.get("editCorrectnessWord", { wordId: props.word.id })} />;

CorrectnessDetail.propTypes = {
  word: MobxPropTypes.observableObject.isRequired
};

export default CorrectnessDetail;
