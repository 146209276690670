import React from "react";

const LoadingSpinner = (_props) => {
  return (
    <div className="guc-loading-spinner">
      <div className="bounce1"></div>
      <div className="bounce2"></div>
      <div className="bounce3"></div>
    </div>
  );
};

export default LoadingSpinner;
