import PropTypes from "prop-types";
import React from "react";
import { PropTypes as MobxPropTypes } from "mobx-react";
import I18n from "i18n-js";

import ContentContainer from "guc/components/ContentContainer";
import TitleBlock from "guc/components/TitleBlock";

import RadioButtonList from "./RadioButtonList.js";
import InputField from "./InputField.js";
import FormFieldInformationDisplay from "./FormFieldInformationDisplay.js";

export default class extends React.Component {
  static propTypes = {
    onSearch: PropTypes.func.isRequired,
    submitted: PropTypes.string,
    subCategory: PropTypes.string,
    isAllowed: PropTypes.string,
    categories: MobxPropTypes.observableArray,
    name: PropTypes.string
  };

  defaultStateFromProps = (props) => {
    return {
      numSubmitted: props.numSubmitted || "0",
      submitted: props.submitted || "all",
      subCategory: props.subCategory || "all",
      isAllowed: props.isAllowed || "all",
      name: props.name || "",
      submittedBy: props.submittedBy || "",
      approvedByOrg: props.approvedByOrg || ""
    };
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState(this.defaultStateFromProps(nextProps));
  }

  handleChange = (key, value) => {
    this.setState({
      [key]: value
    });
  };

  handleSearch = (event) => {
    event.preventDefault();

    const search = {};
    if(this.state.numSubmitted !== "0") {
      search.numSubmitted = this.state.numSubmitted;
    }

    if(this.state.submitted !== "all") {
      search.submitted = this.state.submitted;
    }

    if(this.state.subCategory !== "all") {
      search.subCategory = this.state.subCategory;
    }

    if(this.state.isAllowed !== "all") {
      search.isAllowed = this.state.isAllowed;
    }

    if(this.state.name !== "") {
      search.name = this.state.name;
    }

    if(this.state.submittedBy !== "") {
      search.submittedBy = this.state.submittedBy;
    }

    if(this.state.approvedByOrg !== "") {
      search.approvedByOrg = this.state.approvedByOrg;
    }
    this.props.onSearch(search);
  };

  handleClear = (event) => {
    event.preventDefault();
    this.setState(this.defaultStateFromProps({}));
  };

  state = this.defaultStateFromProps(this.props);

  render() {
    const mainDiv = document.getElementById("main");
    const isAdministrator = mainDiv.dataset.isAdministrator === "true";
    const ishetb1Group = mainDiv.dataset.ishetb1Group;
    const featureApprovedByOrg = mainDiv.dataset.featureApprovedByOrg === "true";

    const numSubmittedProps = {
      title: I18n.t("difficult_words.search.numSubmitted.title"),
      name: "numSubmitted",
      value: this.state.numSubmitted,
      onChange: this.handleChange,
      options: [20, 15, 10, 8, 5, 4, 3, 2, 1, 0].map((value) => {
        return {
          text: `${value}`,
          value: `${value}`
        };
      })
    };

    const submittedProps = {
      title: I18n.t("difficult_words.search.status.title"),
      name: "submitted",
      value: this.state.submitted,
      onChange: this.handleChange,
      options: [
        {
          text: I18n.t("difficult_words.search.status.all"),
          value: "all"
        },
        {
          text: I18n.t("difficult_words.search.status.submitted"),
          value: "true"
        }
      ]
    };

    const subCategoryOptions = [
      {
        text: I18n.t("difficult_words.search.subcategory.all"),
        value: "all"
      }
    ];
    this.props.categories.forEach(({ id, displayName }) => {
      subCategoryOptions.push({
        text: displayName,
        value: id
      });
    });

    const subCategoryProps = {
      title: I18n.t("difficult_words.search.subcategory.title"),
      name: "subCategory",
      value: this.state.subCategory,
      onChange: this.handleChange,
      options: subCategoryOptions
    };

    const allowedProps = {
      title: I18n.t("difficult_words.search.is_allowed.title"),
      name: "isAllowed",
      value: this.state.isAllowed,
      onChange: this.handleChange,
      options: [
        {
          text: I18n.t("difficult_words.search.is_allowed.all"),
          value: "all"
        },
        {
          text: I18n.t("difficult_words.search.is_allowed.true"),
          value: "true"
        },
        {
          text: I18n.t("difficult_words.search.is_allowed.false"),
          value: "false"
        }
      ]
    };

    const ishetb1Props = {
      title: I18n.t("difficult_words.search.ishetb1.title"),
      name: "submittedBy",
      value: this.state.submittedBy,
      onChange: this.handleChange,
      options: [
        {
          text: I18n.t("difficult_words.search.ishetb1.all"),
          value: ""
        },
        {
          text: I18n.t("difficult_words.search.ishetb1.ishetb1"),
          value: ishetb1Group
        }
      ]
    };

    const approvedByOrg = {
      title: I18n.t("difficult_words.search.approved_by_org.title"),
      name: "approvedByOrg",
      value: this.state.approvedByOrg,
      onChange: this.handleChange,
      options: [
        {
          text: I18n.t("difficult_words.search.approved_by_org.all"),
          value: ""
        },
        {
          text: I18n.t("difficult_words.search.approved_by_org.true"),
          value: "true"
        }
      ]
    };

    return (
      <ContentContainer>
        <TitleBlock>{I18n.t("difficult_words.search.title")}</TitleBlock>
        <div className="search-block form-container">
          <form onSubmit={this.handleSearch}>
            {isAdministrator &&
              <FormFieldInformationDisplay label={numSubmittedProps.title} variants="-stacked">
                <select onChange={(event) => { numSubmittedProps.onChange(event.target.name, event.target.value); }}
                  name={numSubmittedProps.name} value={numSubmittedProps.value}>
                  {numSubmittedProps.options.map((option) => (
                    <option key={option.value} value={option.value}>{option.text}</option>
                  ))}
                </select>
              </FormFieldInformationDisplay>}

            <FormFieldInformationDisplay label={I18n.t("difficult_words.search.word.title")} variants="-stacked">
              <InputField name="name"
                value={this.state.name}
                onChange={this.handleChange} />
            </FormFieldInformationDisplay>

            <RadioButtonList {...submittedProps} />

            <RadioButtonList {...subCategoryProps} />

            <RadioButtonList {...allowedProps} />

            {isAdministrator && ishetb1Group &&
            <RadioButtonList {...ishetb1Props} />}

            {!isAdministrator && featureApprovedByOrg &&
            <RadioButtonList {...approvedByOrg} />}

            <div className="button-group">
              <button type="submit">
                {I18n.t("difficult_words.search.buttons.search")}
              </button>
              <button onClick={this.handleClear}>
                {I18n.t("difficult_words.search.buttons.clear")}
              </button>
            </div>
          </form>
        </div>
      </ContentContainer>
    );
  }
}
