import PropTypes from "prop-types";
import React from "react";

import Link from "guc/components/Link";

class NavigationSubItem extends React.Component {
  static propTypes = {
    href: PropTypes.string,
    label: PropTypes.string
  };

  render() {
    return (
      <li>
        <Link href={this.props.href}>{this.props.label}</Link>
      </li>
    );
  }
}

export default NavigationSubItem;
