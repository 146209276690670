import PropTypes from "prop-types";
import React from "react";
import I18n from "i18n-js";

import { inject, observer } from "mobx-react";

import { difficultWordsFilterFromLocation } from "../stores/ViewStore.js";

import ResourceView from "../components/ResourceView.js";
import WordsList from "../components/WordsList.js";

const WordsListObserver = observer(WordsList);

class DifficultWordsList extends React.Component {
  static propTypes = {
    wordsStore: PropTypes.object,
    location: PropTypes.object
  };

  handleDecline = (word) => {
    this.props.wordsStore.updateIsAllowed(word, "false");
  };

  handleApprove = (word) => {
    this.props.wordsStore.updateIsAllowed(word, "true");
  };

  handleLoadMore = () => {
    const filter = difficultWordsFilterFromLocation(this.props.location);
    return this.props.wordsStore.loadMore(filter);
  };

  generatePaginationLink = (page) => {
    const {
      pathname,
      query
    } = this.props.location;

    return {
      pathname,
      query: {
        ...query,
        page: page
      }
    };
  };

  render() {
    const {
      wordsStore
    } = this.props;
    const {
      words,
      pagination
    } = wordsStore.words.value();

    return (
      <WordsListObserver words={words}
        pagination={pagination}
        paginationLinkProps={{generateLink: this.generatePaginationLink}}
        title={I18n.t("difficult_words.title")}
        onApprove={this.handleApprove}
        onDecline={this.handleDecline}
        onLoadMore={this.handleLoadMore} />
    );
  }
}

export default inject("wordsStore")(ResourceView((props) => props.wordsStore.words)(observer(DifficultWordsList)));
