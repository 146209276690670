import PropTypes from "prop-types";
import React from "react";

const Link = (props) => {
  if(props.target) {
    return <a href={props.href} className="guc-hyperlink-element" target={props.target}>{props.children}</a>;
  }

  if(props.onClick) {
    return <a href={props.href} className="guc-hyperlink-element" onClick={props.onClick}>{props.children}</a>;
  }

  return <a href={props.href} className="guc-hyperlink-element">{props.children}</a>;
};

Link.propTypes = {
  children: PropTypes.node,
  href: PropTypes.string,
  target: PropTypes.string,
  onClick: PropTypes.func
};

export default Link;
