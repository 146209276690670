import { action } from "mobx";
import { deserialize, update, createSimpleSchema, list, object } from "serializr";

import Pagination from "../models/Pagination.js";
import Word from "../models/Word.js";
import Resource from "../models/Resource.js";

const initialWords = {
  words: [],
  pagination: {
    page: 1,
    totalPages: 1
  }
};

const loadWordsSchema = createSimpleSchema({
  words: list(object(Word)),
  pagination: object(Pagination)
});

class WordsStore {
  words = new Resource(null, initialWords)

  constructor(wordsTransport) {
    this.wordsTransport = wordsTransport;
  }

  refresh({page, start}, filter = {}) {
    this.words.refresh(
      this.wordsTransport.loadWords({page, start}, filter).then(({words, pagination}) =>
        deserialize(loadWordsSchema, { words, pagination })
      )
    );
  }

  loadMore(filter = {}) {
    const pageStart = this.words.value().pagination.nextStart;
    return this.wordsTransport.loadWords({start: pageStart}, filter).then(action(({words, pagination}) => {
      const wordsResult = deserialize(loadWordsSchema, { words, pagination });
      const oldWords = this.words.value().words;
      wordsResult.words = oldWords.concat(wordsResult.words);
      this.words.refresh(
        Promise.resolve(wordsResult), wordsResult
      );
      this.words._promise._state.set("fulfilled");
    }));
  }

  updateIsAllowed(word, isAllowed) {
    const {
      words,
      pagination
    } = this.words.value();

    this.words.refresh(
      this.wordsTransport.updateIsAllowed(word.id, isAllowed).then((newWord) => {
        update(word, newWord);

        return { words, pagination };
      })
    );
  }
}

export default WordsStore;
