import PropTypes from "prop-types";
import React from "react";

import { Link } from "react-router";

import classNames from "classnames";

import DivFactory from "guc/components/DivFactory";

const NavigationLabel = DivFactory({
  displayName: "NavigationLabel",
  cssClass: "navigation-label"
});

const NavigationItem = (props) => (
  <NavigationLabel>
    <Link to={props.url} className="nav-item" activeClassName="-selected">
      { props.iconClass &&
        <div className={classNames("navicon", props.iconClass)} />
      }
      <div className="nav-label">
        {props.label}
      </div>
    </Link>
  </NavigationLabel>
);

NavigationItem.propTypes = {
  url: PropTypes.string,
  label: PropTypes.string,
  iconClass: PropTypes.string
};

class NavigationItemWithSubItems extends React.Component {
  static propTypes = {
    label: PropTypes.string,
    subItems: PropTypes.array
  };

  state = {
    open: false
  };

  handleClick = (event) => {
    event.preventDefault();

    this.setState({ open: !this.state.open });
  };

  render() {
    const navIconClass = classNames("navicon", this.props.iconClass);
    const submenuClass = classNames("submenu", { "-open": this.state.open });
    const navItemClass = classNames("nav-item", { "-open": this.state.open });


    return (
      <NavigationLabel>
        <div className={navItemClass} onClick={this.handleClick}>
          <div className={navIconClass} />
          <div className="nav-label">{this.props.label}</div>
        </div>

        <ul className={submenuClass}>
          { this.props.subItems.map((item, i) => (
            <li key={i}>
              <NavigationItem {...item} />
            </li>
          ))}
        </ul>
      </NavigationLabel>
    );
  }
}

const NavigationBlock = (props) => (
  <nav className="navigation-block">
    <ul className="unordered-list -no-style" >
      {props.items.map((item, i) =>
        <li className="list-item" key={i}>
          { item.subItems
            ? <NavigationItemWithSubItems {...item} />
            : <NavigationItem {...item} />
          }
        </li>
      )}
    </ul>
  </nav>
);

NavigationBlock.propTypes = {
  items: PropTypes.array
};

export default NavigationBlock;
