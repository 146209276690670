import PropTypes from "prop-types";
import React from "react";

import InfiniteScroll from "react-infinite-scroll-component";
import LoadingSpinner from "guc/components/LoadingSpinner";

class InfiniteScrollList extends React.Component {
  static propTypes = {
    onLoadMore: PropTypes.func.isRequired,
    hasMore: PropTypes.bool,
    children: PropTypes.node
  }

  constructor(props) {
    super(props);

    this.state = {
      loadMorePromise: Promise.resolve({})
    };
  }

  loadMoreHandler = () => {
    this.setState((prevState, props) => {
      return {
        loadMorePromise: prevState.loadMorePromise.then(() => {
          return props.onLoadMore();
        })
      };
    });
  }

  render() {
    return (
      <InfiniteScroll
        next={this.loadMoreHandler}
        hasMore={this.props.hasMore}
        loader={<LoadingSpinner />}>
        {this.props.children}
      </InfiniteScroll>
    );
  }
}

export default InfiniteScrollList;
