import PropTypes from "prop-types";
import React from "react";

import ApproveAction from "./ApproveAction.js";
import DeclineAction from "./DeclineAction.js";
import OpinionActiveAction from "./OpinionActiveAction.js";

const OpinionsCircles = (props) => {
  const {
    opinions,
    onApprove,
    onApproveTooltip,
    onDecline,
    onDeclineToolTip,
    onActive,
    onActiveTooltip
  } = props;
  const {
    isAllowed,
    isNotAllowed,
    isActive
  } = opinions;

  return (
    <div className="opinions-circles">
      { onApprove &&
        <ApproveAction onClick={onApprove} opinionsAmount={isAllowed} tooltip={onApproveTooltip} />
      }
      { onDecline &&
        <DeclineAction onClick={onDecline} opinionsAmount={isNotAllowed} tooltip={onDeclineToolTip} />
      }

      { onActive &&
        <OpinionActiveAction onClick={onActive} opinionsAmount={isActive} tooltip={onActiveTooltip} />
      }
    </div>
  );
};

OpinionsCircles.propTypes = {
  opinions: PropTypes.shape({
    allowed: PropTypes.number,
    isNotAllowed: PropTypes.number,
    isActive: PropTypes.number
  }),
  onApprove: PropTypes.func,
  onDecline: PropTypes.func,
  onActive: PropTypes.func
};

export default OpinionsCircles;
