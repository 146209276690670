import PropTypes from "prop-types";
import React from "react";

import { inject, observer, propTypes as MobxPropTypes } from "mobx-react";

import history from "../history";
import routes from "../routes.js";

import CorrectnessNew from "../components/CorrectnessNew.js";
import ResourceView from "../components/ResourceView.js";

class CorrectnessNewContainer extends React.Component {
  static propTypes = {
    wordStore: PropTypes.shape({
      word: MobxPropTypes.observableObject,
      newWord: PropTypes.func.isRequired,
      createWord: PropTypes.func.isRequired
    }).isRequired
  };

  handleSave = () => {
    const {
      wordStore
    } = this.props;
    const word = wordStore.word.value();

    wordStore.createWord(word).then((newWord) => {
      history.push(routes.get("showCorrectnessWord", { wordId: newWord.id }));
    }, (_error) => {
      // TODO
    });
  };

  render() {
    const {
      wordStore
    } = this.props;
    const word = wordStore.word.value();

    return (
      <CorrectnessNew word={word}
        onSave={this.handleSave} />
    );
  }
}

export default inject("wordStore")(ResourceView((props) => props.wordStore.word)(observer(CorrectnessNewContainer)));
