import superagent from "./superagent";

class WordsTransport {
  loadWords({page, start}, filter = {}) {
    return superagent.get("/api/words").
      query({
        page: page,
        start: start === null ? undefined : start,
        num_submitted: filter.numSubmitted,
        submitted: filter.submitted,
        sub_category: filter.subCategory,
        is_allowed: filter.isAllowed,
        is_active: filter.isActive,
        name: filter.name,
        main_category: filter.mainCategory,
        submitted_by: filter.submittedBy,
        approved_by_org: filter.approvedByOrg
      }).then((response) => {
        return response.body;
      }, (error) => {
        this.handleError(error);
        throw error;
      });
  }

  loadWord(id) {
    return superagent.get("/api/words/" + id).then((response) => {
      return response.body;
    }, (error) => {
      this.handleError(error);
      throw error;
    });
  }

  updateIsAllowed(id, isAllowed) {
    return superagent.patch("/api/words/" + id).
      setCsrfToken().
      send({isAllowed: isAllowed}).then((response) => {
        return response.body;
      }, (error) => {
        this.handleError(error);
        throw error;
      });
  }

  createWord(word) {
    return superagent.post("/api/words").
      setCsrfToken().
      send(word).then((response) => {
        return response.body;
      }, (error) => {
        this.handleError(error);
        throw error;
      });
  }

  updateWord(word) {
    return superagent.put("/api/words/" + word.id).
      setCsrfToken().
      send(word).then((response) => {
        return response.body;
      }, (error) => {
        this.handleError(error);
        throw error;
      });
  }

  destroyWord(wordId) {
    return superagent.delete("/api/words/" + wordId)
      .setCsrfToken()
      .then((response) =>
        response.body
      , (error) => {
        this.handleError(error);
        throw error;
      });
  }

  destroySubmittedWord(wordId) {
    return superagent.delete("/api/words/submitted_destroy/" + wordId)
      .setCsrfToken()
      .then((response) =>
        response.body
      , (error) => {
        this.handleError(error);
        throw error;
      });
  }

  handleError(error) {
    // TODO: Optionally do something, logging for now
    if (process.env.NODE_ENV !== "production") {
      console.error(error);
    }
  }

  updateAlternativeIsAllowed(word, alternative, isAllowed) {
    const newAlternative = Object.assign({}, alternative, { isAllowed: isAllowed, isActive: true });

    return superagent.put("/api/words/" + word.id + "/alternatives/" + alternative.id)
      .setCsrfToken()
      .send(newAlternative)
      .then((response) =>
        response.body
      , (error) => {
        this.handleError(error);
        throw error;
      });
  }

  updateAlternativeIsActive(word, alternative, isActive) {
    const newAlternative = Object.assign({}, alternative, { isActive: isActive });

    return superagent.put("/api/words/" + word.id + "/alternatives/" + alternative.id)
      .setCsrfToken()
      .send(newAlternative)
      .then((response) =>
        response.body
      , (error) => {
        this.handleError(error);
        throw error;
      });
  }
}

export default WordsTransport;
