import React from "react";
import { PropTypes as MobxPropTypes } from "mobx-react";
import I18n from "i18n-js";

import TableHeader from "guc/components/TableHeader";
import TableBlock from "guc/components/TableBlock";
import TableContent from "guc/components/TableContent";

import OrganizationNamesTableRow from "./OrganizationNamesTableRow.js";

class OrganizationNamesTable extends React.Component {
  static propTypes = {
    words: MobxPropTypes.observableArray
  };

  render() {
    const {
      words,
      ...other
    } = this.props;

    return (
      <TableBlock variants="-flex">
        <TableHeader fields={[
            I18n.t("organization_names.list_headers.name"),
            I18n.t("organization_names.list_headers.word_in_text"),
            ""
        ]} />
        <TableContent>
          {words.map((word) => <OrganizationNamesTableRow key={word.id} word={word} {...other} /> )}
        </TableContent>
      </TableBlock>
    );
  }
}

export default OrganizationNamesTable;
