import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router";

class PaginationLink extends React.Component {
  static propTypes = {
    current: PropTypes.bool,
    page: PropTypes.number.isRequired,
    label: PropTypes.string,
    generateLink: PropTypes.func.isRequired
  };

  label = () => {
    return this.props.label || this.props.page;
  };

  render() {
    return (
      <Link className={this.props.current ? "page current" : "page"}
        to={this.props.generateLink(this.props.page)}>
        {this.label()}
      </Link>
    );
  }
}

export default PaginationLink;
