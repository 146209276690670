import PropTypes from "prop-types";
import React from "react";

class ValidationErrors extends React.Component {
  static propTypes = {
    errors: PropTypes.arrayOf(
      PropTypes.shape({
        attribute: PropTypes.string,
        message: PropTypes.string
      })
    )
  };

  render() {
    return (
      <div className="alert-box -rails alert">
        <ul>
          {this.props.errors.map((error) => {
            return (
              <li key={error.attribute + error.message}>{error.attribute}: {error.message}</li>
            );
          })}
        </ul>
      </div>
    );
  }
}

export default ValidationErrors;
