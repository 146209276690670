import { observable } from "mobx";
import { fromPromise } from "mobx-utils";

class Resource {
  @observable _promise;

  constructor(promise, initialValue = undefined) {
    if(!promise) {
      promise = Promise.resolve(initialValue);
    }

    this.refresh(promise, initialValue);
  }

  refresh(promise, initialValue = undefined) {
    this._promise = fromPromise(promise, initialValue);
    return this._promise.promise;
  }

  value() {
    return this._promise.state === "fulfilled" && this._promise.value;
  }

  error() {
    return this._promise.state === "rejected" && this._promise.value;
  }

  promise() {
    return this._promise.promise;
  }

  state() {
    return this._promise.state;
  }

  case(handlers) {
    return this._promise.case(handlers);
  }

  valueOrInitial() {
    return this._promise.value;
  }
}

export default Resource;
