import PropTypes from "prop-types";
import React from "react";
import I18n from "i18n-js";

import { propTypes, observer } from "mobx-react";

import TitleBlock from "guc/components/TitleBlock";

import TableBlock from "guc/components/TableBlock";
import TableHeader from "guc/components/TableHeader";
import TableContent from "guc/components/TableContent";
import TableRow from "guc/components/TableRow";
import TableField from "guc/components/TableField";

class AlternativeRow extends React.Component {
  static propTypes = {
    index: PropTypes.number.isRequired,
    alternative: PropTypes.shape({
      name: PropTypes.string,
      isAllowed: PropTypes.bool
    }).isRequired,
    onDestroy: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired
  };

  handleDestroy = () => {
    this.props.onDestroy(this.props.index);
  };

  handleChange = (type, event) => {
    const updatedAlternative = Object.assign({}, this.props.alternative, { [type]: event.target.value });

    this.props.onChange(this.props.index, updatedAlternative);
  };

  render() {
    return (
      <TableRow>
        <TableField>
          <input type="text" value={this.props.alternative.name} onChange={this.handleChange.bind(this, "name")} />
        </TableField>
        <TableField variants="-actions">
          <div className="guc-destroy-action" onClick={this.handleDestroy} title={I18n.t("actions.titles.destroyAlternative")}/>
        </TableField>
      </TableRow>
    );
  }
}

class AlternativesListForm extends React.Component {
  static propTypes = {
    title: PropTypes.string,
    listTitle: PropTypes.string,
    alternatives: propTypes.arrayOrObservableArray.isRequired
  };

  handleAdd = () => {
    this.props.alternatives.push({ name: "" });
  };

  handleChange = (index, value) => {
    this.props.alternatives[index] = value;
  };

  handleDestroy = (index) => {
    this.props.alternatives.splice(index, 1);
  };

  render() {
    return (
      <div>
        <TitleBlock>
          {this.props.title || I18n.t("difficult_words.alternatives.title")}
          <div className="guc-add-action" onClick={this.handleAdd} title={I18n.t("actions.titles.addAlternative")} />
        </TitleBlock>
        <div className="content">
          <TableBlock>
            <TableHeader fields={[
              this.props.listTitle || I18n.t("difficult_words.alternatives.alternative"),
              ""]} />
            <TableContent>
              {this.props.alternatives.map((alternative, index) =>
                <AlternativeRow
                  key={alternative.id ? `ID_${alternative.id}` : `index${index}`}
                  index={index}
                  alternative={alternative}
                  onDestroy={this.handleDestroy}
                  onChange={this.handleChange} />
              )}
            </TableContent>
          </TableBlock>
        </div>
      </div>
    );
  }
}

export default observer(AlternativesListForm);
