import PropTypes from "prop-types";
import React from "react";
import { PropTypes as MobxPropTypes } from "mobx-react";
import I18n from "i18n-js";

import ContentContainer from "guc/components/ContentContainer";
import TitleBlock from "guc/components/TitleBlock";

import CorrectnessForm from "./CorrectnessForm.js";

import routes from "../routes.js";

class OrganizationNameNew extends React.Component {
  static propTypes = {
    word: MobxPropTypes.observableObject.isRequired,
    onSave: PropTypes.func.isRequired
  };

  render() {
    const word = this.props.word;

    return (
      <div className="organisation-name-new">
        <ContentContainer>
          <TitleBlock>
            {I18n.t("organization_names.new.title")}
          </TitleBlock>

          <CorrectnessForm onSave={this.props.onSave}
            word={word}
            cancelPath={routes.get("organizationNames")} />
        </ContentContainer>
      </div>
    );
  }
}

export default OrganizationNameNew;
