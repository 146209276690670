import PropTypes from "prop-types";
import React from "react";

import { propTypes } from "mobx-react";

import I18n from "i18n-js";

import AlternativesListForm from "./AlternativesListForm.js";
import CorrectnessInformationForm from "./CorrectnessInformationForm.js";
import SaveCancel from "./SaveCancel.js";
import TwoColumns from "./TwoColumns.js";
import ValidationErrors from "./ValidationErrors.js";

class CorrectnessForm extends React.Component {
  static propTypes = {
    word: PropTypes.shape({
      alternatives: propTypes.arrayOrObservableArray,
      validate: PropTypes.func.isRequired,
      tidyUp: PropTypes.func.isRequired
    }).isRequired,
    onSave: PropTypes.func.isRequired,
    cancelPath: PropTypes.string.isRequired
  };

  state = {
    validationErrors: []
  };

  handleSubmit = (event) => {
    event.preventDefault();

    const validationErrors = this.props.word.validate();
    if (validationErrors.length > 0) {
      this.setState({validationErrors});
    } else {
      this.props.word.tidyUp();
      this.props.onSave();
    }
  };

  render() {
    const word = this.props.word;
    const {
      validationErrors
    } = this.state;

    return (
      <form onSubmit={this.handleSubmit}>
        {validationErrors.length > 0 &&
          <ValidationErrors errors={validationErrors} />}

        <TwoColumns>
          <CorrectnessInformationForm word={word} />
          <AlternativesListForm alternatives={word.alternatives}
            title={I18n.t("components.CorrectnessForm.alternatives.title")}
            listTitle={I18n.t("components.CorrectnessForm.alternatives.list_title")} />
        </TwoColumns>

        <SaveCancel cancelPath={this.props.cancelPath} />
      </form>
    );
  }
}

export default CorrectnessForm;
