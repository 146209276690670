import { serializable } from "serializr";

export default class Pagination {
  @serializable
  page;
  @serializable
  totalPages;
  @serializable
  nextStart;
}
