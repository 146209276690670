import PropTypes from "prop-types";
import React from "react";
import { PropTypes as MobxPropTypes } from "mobx-react";

import ContentContainer from "guc/components/ContentContainer";
import TitleBlock from "guc/components/TitleBlock";

import routes from "../routes.js";

import CorrectnessForm from "./CorrectnessForm.js";

class CorrectnessEdit extends React.Component {
  static propTypes = {
    word: MobxPropTypes.observableObject.isRequired,
    onSave: PropTypes.func
  };

  render() {
    const word = this.props.word;
    const cancelPath = routes.get("showOrganizationName", { wordId: word.id });

    return (
      <div className="word-detail">
        <ContentContainer>
          <TitleBlock>
            {word.name}
          </TitleBlock>

          <CorrectnessForm onSave={this.props.onSave}
            word={word}
            cancelPath={cancelPath} />
        </ContentContainer>
      </div>
    );
  }
}

export default CorrectnessEdit;
