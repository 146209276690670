import PropTypes from "prop-types";
import React from "react";
import I18n from "i18n-js";
import { observer, inject, PropTypes as MobxPropTypes } from "mobx-react";

import InformationDisplay from "guc/components/InformationDisplay";

class CorrectnessWordInformationAttributes extends React.Component {
  static propTypes = {
    word: MobxPropTypes.observableObject.isRequired,
    categoriesStore: PropTypes.object
  };

  render() {
    const {
      word,
      categoriesStore
    } = this.props;
    const subCategory = categoriesStore.displayForSubCategory(word.subCategory);

    return (
      <div>
        <InformationDisplay>
          <span>{I18n.t("correctness_words.detail.name")}</span>
          <span>{word.name}</span>
        </InformationDisplay>
        <InformationDisplay>
          <span>{I18n.t("correctness_words.detail.category")}</span>
          <span>{subCategory}</span>
        </InformationDisplay>
        <InformationDisplay>
          <span>{I18n.t("correctness_words.detail.is_allowed")}</span>
          <span>{word.displayIsCorrectnessAllowed}</span>
        </InformationDisplay>
      </div>
    );
  }
}

export default inject("categoriesStore")(observer(CorrectnessWordInformationAttributes));
