import React from "react";
import { render } from "react-dom";

import { Provider } from "mobx-react";

import history from "./history.js";
import Router from "./containers/Router.js";
import stores from "./stores/index.js";

window.$(function() {
  const mainDiv = document.getElementById("main");
  if(mainDiv) {
    render((
      <Provider {...stores}>
        <Router history={history} />
      </Provider>
    ), mainDiv);
  }
});
