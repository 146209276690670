import PropTypes from "prop-types";
import React from "react";

import range from "lodash.range";
import times from "lodash.times";

const PaginationFactory = (linkField) => {
  const PaginationLink = linkField;
  const PreviousPages = (props) => {
    return (
      <div className="previous-pages">
        {(props.totalPages > 1 && props.page > 1) ?
          <div>
            <PaginationLink label="<<"
              page={1}
              {...props.linkProps} />
            <PaginationLink label="<"
              page={props.page - 1}
              {...props.linkProps} />
          </div>
          : null}
      </div>
    );
  };

  PreviousPages.propTypes = {
    page: PropTypes.number,
    totalPages: PropTypes.number,
    linkProps: PropTypes.object
  };

  const NextPages = (props) => {
    return (
      <div className="next-pages">
        {(props.totalPages > 1 && props.page < props.totalPages) ?
          <div>
            <PaginationLink label=">"
              page={props.page + 1}
              {...props.linkProps} />
            <PaginationLink label=">>"
              page={props.totalPages}
              {...props.linkProps} />
          </div>
          : null}
      </div>
    );
  };

  NextPages.propTypes = {
    page: PropTypes.number,
    totalPages: PropTypes.number,
    linkProps: PropTypes.object
  };

  class Pagination extends React.Component {
    static propTypes = {
      totalPages: PropTypes.number,
      page: PropTypes.number,
      window: PropTypes.number,
      linkProps: PropTypes.object
    };

    static defaultProps = {
      linkProps: {}
    };

    pageNumbers = () => {
      return range(this.props.totalPages).map((i) => i+1);
    };

    currentPage = () => {
      return this.props.page;
    };

    hasMultiplePages = () => {
      return this.props.totalPages > 1;
    };

    window = () => {
      return this.props.window || 2;
    };

    previousPages = () => {
      const linkAmount = this.currentPage() - this.window() <= 1
        ? new Array(this.currentPage()).length - 1
        : this.window();

      return (
        times(linkAmount, (count) =>
          <PaginationLink key={count}
            page={this.currentPage() - count - 1}
            {...this.props.linkProps} />
        ).reverse()
      );
    };

    nextPages = () => {
      const linkAmount = this.currentPage() + this.window() >= this.props.totalPages
        ? this.props.totalPages - this.currentPage()
        : this.window();

      return (
        times(linkAmount, (count) =>
          <PaginationLink key={count}
            page={this.currentPage() + count + 1}
            {...this.props.linkProps} />
        )
      );
    };

    render() {
      if (this.hasMultiplePages()) {
        return (
          <div className="pagination-block">
            <PreviousPages {...this.props} />
            <div className="pagination">
              {this.previousPages()}

              <PaginationLink key={this.currentPage()}
                current={this.currentPage() === this.currentPage()}
                page={this.currentPage()}
                {...this.props.linkProps} />

              {this.nextPages()}
            </div>
            <NextPages {...this.props} />
          </div>
        );
      } else {
        return null;
      }
    }
  }

  return Pagination;
};

export default PaginationFactory;
