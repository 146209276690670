import PropTypes from "prop-types";
import React from "react";

import ContentContainer from "guc/components/ContentContainer";
import TitleBlock from "guc/components/TitleBlock";
import LoadingSpinner from "guc/components/LoadingSpinner";

class LoadingComponent extends React.Component {
  static propTypes = {
    title: PropTypes.string
  };

  static defaultProps = {
    title: "Laden..."
  };

  render() {
    return (
      <ContentContainer>
        <TitleBlock>{this.props.title}</TitleBlock>
        <LoadingSpinner />
      </ContentContainer>
    );
  }
}

export default LoadingComponent;
