import React from "react";

import NavigationBlock from "./NavigationBlock.js";

const NavigationSidebar = (props) => (
  <div className="navigation-sidebar">
    <div className="logo" />
    <NavigationBlock {...props} />
  </div>
);

export default NavigationSidebar;
