import PropTypes from "prop-types";
import React from "react";
import { PropTypes as MobxPropTypes } from "mobx-react";

import { Link } from "react-router";

import I18n from "i18n-js";

import InfiniteScrollList from "./InfiniteScrollList.js";

import routes from "../routes.js";

import ContentContainer from "guc/components/ContentContainer";
import TitleBlock from "guc/components/TitleBlock";

import PaginationBlock from "./PaginationBlock.js";
import CorrectnessTable from "./CorrectnessTable.js";
import ListDisplay from "./ListDisplay.js";

class CorrectnessList extends React.Component {
  static propTypes = {
    words: MobxPropTypes.observableArray,
    onApprove: PropTypes.func,
    onDecline: PropTypes.func,
    onLoadMore: PropTypes.func,
    pagination: PropTypes.shape({
      page: PropTypes.number,
      totalPages: PropTypes.number,
      nextStart: PropTypes.string
    }),
    paginationLinkProps: PropTypes.object
  };

  render() {
    const title = I18n.t("correctness_words.title");

    return (
      <div className="correctness-list">
        <ContentContainer>
          <TitleBlock>
            {title}
            <Link to={routes.get("newCorrectnessWord")} title={I18n.t("actions.titles.newCorrectnessWord")}>
              <div className="guc-add-action" />
            </Link>
          </TitleBlock>

          <ListDisplay hasData={this.props.words.length > 0}>
            <div>
              <InfiniteScrollList onLoadMore={this.props.onLoadMore} hasMore={this.props.pagination.nextStart != null}>
                <CorrectnessTable words={this.props.words}
                  onApprove={this.props.onApprove}
                  onDecline={this.props.onDecline} />
              </InfiniteScrollList>

              <PaginationBlock {...this.props.pagination} linkProps={this.props.paginationLinkProps} />
            </div>
          </ListDisplay>
        </ContentContainer>
      </div>
    );
  }
}

export default CorrectnessList;
