import PropTypes from "prop-types";
import React from "react";
import { observer, inject, propTypes } from "mobx-react";

import { Link }  from "react-router";

import classNames from "classnames";

import I18n from "i18n-js";

import routes from "../routes.js";

import OpinionsList from "./OpinionsList.js";

class WordsTableRow extends React.Component {
  static propTypes = {
    word: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      alternatives: propTypes.arrayOrObservableArray
    }),
    onApprove: PropTypes.func.isRequired,
    onDecline: PropTypes.func.isRequired
  };

  handleApprove = () => {
    this.props.onApprove(this.props.word);
  };

  handleDecline = () => {
    this.props.onDecline(this.props.word);
  };

  handleDestroySubmitted = () => {
    this.props.wordStore.destroySubmittedWord(this.props.word.id).then(() => {
      // refreshing the page, so the deleted word is not shown in the UI.
      window.location.reload();
      return false;
    });
  };

  render() {
    const {
      word,
      categoriesStore
    } = this.props;

    const subCategory = categoriesStore.displayForSubCategory(word.subCategory);

    const cssVariants = classNames({
      simple: word.isSimple,
      difficult: word.isDifficult
    });

    const fields = [
      word.name,
      subCategory,
      word.alternatives.map((alternative) => alternative.name).join(", ")
    ];
    const actions = [
      <Link key="showDifficultWord" to={routes.get("showDifficultWord", { wordId: word.id })}><div className="guc-view-action" title={I18n.t("actions.titles.showDifficultWord")}/></Link>,
      <Link key="editDifficultWord" to={routes.get("editDifficultWord", { wordId: word.id })}><div className="guc-edit-action" title={I18n.t("actions.titles.editDifficultWord")}/></Link>
    ];

    // only isHetB1'ers can delete words, but only words that are not assessed
    const userIsIshetb1Group = "userIsIshetb1Group" in document.getElementById("main").dataset;
    const isWordAssessed = word.isSimple || word.isDifficult;

    return (
      <OpinionsList variants={cssVariants}
        opinions={word.opinions}
        fields={fields}
        actions={actions}
        destroyAction={userIsIshetb1Group && !isWordAssessed &&
          <div className="guc-destroy-action" onClick={this.handleDestroySubmitted} title={I18n.t("actions.titles.destroyAlternative")}/>
        }
        onApprove={this.handleApprove}
        onApproveTooltip={I18n.t("components.WordsTableRow.tooltips.approve",
          { category: subCategory.toLowerCase() }
        )}
        onDecline={this.handleDecline}
        onDeclineTooltip={I18n.t("components.WordsTableRow.tooltips.decline", { category: word.subCategory.toLowerCase() })} />
    );
  }
}

export default inject("categoriesStore", "wordStore")(observer(WordsTableRow));
