import PropTypes from "prop-types";
import React from "react";

import { inject, observer, propTypes as MobxPropTypes } from "mobx-react";

import history from "../history";
import routes from "../routes.js";

import WordEdit from "../components/WordEdit";
import ResourceView from "../components/ResourceView.js";

class WordEditContainer extends React.Component {
  static propTypes = {
    wordStore: PropTypes.shape({
      word: MobxPropTypes.observableObject,
      isReady: PropTypes.bool,
      loadWord: PropTypes.func,
      updateWord: PropTypes.func
    }).isRequired,
    params: PropTypes.shape({
      wordId: PropTypes.string.isRequired
    }).isRequired
  };

  handleSave = () => {
    const {
      wordStore
    } = this.props;
    const word = wordStore.word.value();

    wordStore.updateWord(word).then((newWord) => {
      history.push(routes.get("showDifficultWord", {wordId: newWord.id}));
    }, (_error) => {
      // TODO
    });
  };

  render() {
    const {
      wordStore
    } = this.props;
    const word = wordStore.word.value();

    return (
      <WordEdit word={word}
        onSave={this.handleSave} />
    );
  }
}

export default inject("wordStore")(ResourceView((props) => props.wordStore.word)(observer(WordEditContainer)));
