import superagent from "./superagent";

class CategoriesTransport {
  loadCategories(filter = {}) {
    return superagent.get("/api/categories")
      .query({
        main_category: filter.mainCategory
      })
      .then((response) => {
        return response.body;
      }, (error) => {
        this.handleError(error);
        throw error;
      });
  }

  handleError(error) {
    // TODO: Optionally do something, logging for now
    if (process.env.NODE_ENV !== "production") {
      console.error(error);
    }
  }
}

export default CategoriesTransport;
