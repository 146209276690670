import PropTypes from "prop-types";
import React from "react";

import FooterBlock from "guc/components/FooterBlock";

const KTFooterBlock = (props) => {
  const {
    children,
    initialYear,
    ...footerBlockProps
  } = props;

  const currentYear = new Date().getFullYear();

  return (
    <FooterBlock {...footerBlockProps}>
      {children}
      <div className="copyright">
        <span className="text">Copyright Klinkende Taal</span>
        ©
        { initialYear ? <span className="year">{initialYear} - {currentYear}</span> : <span>{currentYear}</span> }
      </div>
    </FooterBlock>
  );
};

KTFooterBlock.propTypes = {
  children: PropTypes.node,
  initialYear: PropTypes.string
};

export default KTFooterBlock;
