import React from "react";

import { observer } from "mobx-react";

import LoadingComponent from "../components/LoadingComponent.js";
import ErrorComponent from "../components/ErrorComponent.js";
import ConflictError from "../components/ConflictError.js";

const ResourceView = (resourceSelector = (props) => props.resource, valueProp = undefined) => (Component) => {
  const resourceView = observer((props) => {
    return resourceSelector(props).case({
      /* eslint-disable react/display-name */
      pending: () => <LoadingComponent />,
      rejected: (error) => error.status === 409 ? <ConflictError error={error} /> : <ErrorComponent error={error} />,
      fulfilled: (value) => <Component {...props} {...(valueProp ? { [valueProp]: value } : {})} />
      /* eslint-enable react/display-name */
    });
  });
  resourceView.displayName = "ResourceView";
  return resourceView;
};

export default ResourceView;
