import PropTypes from "prop-types";
import React from "react";
import I18n from "i18n-js";
import { observer, inject, PropTypes as MobxPropTypes } from "mobx-react";

import InformationDisplay from "guc/components/InformationDisplay";

class WordInformationAttributes extends React.Component {
  static propTypes = {
    word: MobxPropTypes.observableObject.isRequired,
    categoriesStore: PropTypes.object
  };

  render() {
    const {
      word,
      categoriesStore
    } = this.props;
    const subCategory = categoriesStore.displayForSubCategory(word.subCategory);

    return (
      <div>
        <InformationDisplay>
          <span>{I18n.t("difficult_words.detail.name")}</span>
          <span>{word.name}</span>
        </InformationDisplay>
        <InformationDisplay>
          <span>{I18n.t("difficult_words.detail.category")}</span>
          <span>{subCategory}</span>
        </InformationDisplay>
        <InformationDisplay>
          <span>{I18n.t("difficult_words.detail.description")}</span>
          <span>{word.description}</span>
        </InformationDisplay>
        <InformationDisplay>
          <span>{I18n.t("difficult_words.detail.is_allowed")}</span>
          <span>{word.displayIsAllowed}</span>
        </InformationDisplay>
        <InformationDisplay>
          <span>{I18n.t("difficult_words.detail.reason")}</span>
          <span>{word.reason}</span>
        </InformationDisplay>
      </div>
    );
  }
}

export default inject("categoriesStore")(observer(WordInformationAttributes));
