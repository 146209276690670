import PropTypes from "prop-types";
import React from "react";
import { PropTypes as MobxPropTypes } from "mobx-react";
import I18n from "i18n-js";

import ContentContainer from "guc/components/ContentContainer";
import TitleBlock from "guc/components/TitleBlock";

import WordForm from "./WordForm.js";

import routes from "../routes.js";

class WordNew extends React.Component {
  static propTypes = {
    word: MobxPropTypes.observableObject.isRequired,
    onSave: PropTypes.func.isRequired
  };

  render() {
    const word = this.props.word;

    return (
      <div className="word-detail">
        <ContentContainer>
          <TitleBlock>
            {I18n.t("difficult_words.new.title")}
          </TitleBlock>

          <WordForm onSave={this.props.onSave}
            word={word}
            cancelPath={routes.get("difficultWords")} />
        </ContentContainer>
      </div>
    );
  }
}

export default WordNew;
