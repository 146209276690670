import React from "react";

import I18n from "i18n-js";

import ContentContainer from "guc/components/ContentContainer";
import TitleBlock from "guc/components/TitleBlock";

const ConflictError = () => (
  <ContentContainer>
    <TitleBlock>{I18n.t("errors.conflict.title")}</TitleBlock>
    <div>{I18n.t("errors.conflict.message")}</div>
  </ContentContainer>
);

export default ConflictError;
