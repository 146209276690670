import PropTypes from "prop-types";
import React from "react";
import I18n from "i18n-js";

import { observer, inject } from "mobx-react";

import FormFieldInformationDisplay from "./FormFieldInformationDisplay.js";

class WordInformationForm extends React.Component {
  static propTypes = {
    word: PropTypes.shape({
      name: PropTypes.string.isRequired,
      subCategory: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      isAllowed: PropTypes.string.isRequired
    })
  };

  updateProperty = (key, value) => {
    this.props.word[key] = value;
  };

  onChange = (event) => {
    this.updateProperty(event.target.name, event.target.value);
  };

  render() {
    const {
      word,
      categoriesStore
    } = this.props;
    const {
      categories
    } = categoriesStore.categories.valueOrInitial();

    const subCategoryOptions = [];
    categories.forEach((category) => {
      subCategoryOptions.push(
        <option value={category.id} key={category.id}>{category.displayName}</option>
      );
    });

    const isVakterm = word.subCategory.toUpperCase().includes("VAKTERM");

    return (
      <div className="information-form">
        <FormFieldInformationDisplay label={I18n.t("difficult_words.detail.name")}>
          <input type="text" name="name" value={word.name} onChange={this.onChange} />
        </FormFieldInformationDisplay>
        <FormFieldInformationDisplay label={I18n.t("difficult_words.detail.category")}>
          <select name="subCategory" value={word.subCategory} onChange={this.onChange}>
            {subCategoryOptions}
          </select>
        </FormFieldInformationDisplay>
        <FormFieldInformationDisplay label={I18n.t("difficult_words.detail.description")}>
          <input type="text" name="description" value={word.description} onChange={this.onChange} />
        </FormFieldInformationDisplay>
        <FormFieldInformationDisplay label={I18n.t("difficult_words.detail.is_allowed")}>
          <select value={word.isAllowed} name="isAllowed" onChange={this.onChange}>
            <option value="true">{I18n.t("difficult_words.search.is_allowed.true")}</option>
            <option value="false">{I18n.t("difficult_words.search.is_allowed.false")}</option>
          </select>
        </FormFieldInformationDisplay>
        <FormFieldInformationDisplay label={I18n.t("difficult_words.detail.reason")}>
          <select value={word.reason} name="reason" onChange={this.onChange}>
            {isVakterm && <option value="vakterm">{I18n.t("difficult_words.ishetb1.categories.vakterm")}</option>}
            {!isVakterm &&
              <React.Fragment>
                <option value="standaard">{I18n.t("difficult_words.ishetb1.categories.standaard")}</option>
                <option value="ambigu">{I18n.t("difficult_words.ishetb1.categories.ambigu")}</option>
                <option value="te lang">{I18n.t("difficult_words.ishetb1.categories.te_lang")}</option>
                <option value="te abstract">{I18n.t("difficult_words.ishetb1.categories.te_abstract")}</option>
                <option value="ouderwets">{I18n.t("difficult_words.ishetb1.categories.ouderwets")}</option>
              </React.Fragment>
            }
          </select>
        </FormFieldInformationDisplay>
      </div>
    );
  }
}

export default inject("categoriesStore")(observer(WordInformationForm));
