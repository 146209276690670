import { observable, computed } from "mobx";
import { serializable, identifier, list, createSimpleSchema, object } from "serializr";
import I18n from "i18n-js";

import Alternative from "./Alternative.js";

const opinionsSchema = createSimpleSchema({
  isAllowed: true,
  isNotAllowed: true,
  isActive: true
});

class Word {
  @serializable(identifier()) id;
  @observable @serializable name = "";
  @observable @serializable description = "";
  @observable @serializable mainCategory = "";
  @observable @serializable subCategory = "";
  @observable @serializable(list(object(Alternative))) alternatives = [];
  @observable @serializable isAllowed = "";
  @observable @serializable isActive = "";
  @observable @serializable reason = "";
  @observable @serializable(object(opinionsSchema)) opinions = {
    isAllowed: 0,
    isNotAllowed: 0,
    isActive: 0
  };

  @computed get isSimple() {
    return this.isAllowed === "true";
  }

  @computed get isDifficult() {
    return this.isAllowed === "false";
  }

  @computed get isCurrentlyActive() {
    return this.isActive === "true";
  }

  @computed get displayIsAllowed() {
    if (this.isSimple) {
      return I18n.t("difficult_words.search.is_allowed.true");
    } else if(this.isDifficult) {
      return I18n.t("difficult_words.search.is_allowed.false");
    } else {
      return "";
    }
  }

  @computed get displayIsCorrectnessAllowed() {
    if (this.isSimple) {
      return I18n.t("correctness_words.is_allowed.true");
    } else if(this.isDifficult) {
      return I18n.t("correctness_words.is_allowed.false");
    } else {
      return "";
    }
  }

  validate() {
    const errors = [];

    if (this.name.trim() === "") {
      errors.push({
        attribute: I18n.t("attributes.word"),
        message: I18n.t("errors.word.empty")
      });
    }

    if (this.alternatives.length > 0 && this.alternatives.includes("")) {
      errors.push({
        attribute: I18n.t("attributes.alternatives"),
        message: I18n.t("errors.alternatives.empty")
      });
    }

    return errors;
  }

  tidyUp() {
    this.name = this.name.trim();
    this.description = this.description.trim();
    this.alternatives = this.alternatives.map((alternative) => {
      alternative.name.trim();
      return alternative;
    });
  }
}

export default Word;
