import React from "react";
import PropTypes from "prop-types";

import classNames from "classnames";

const FormFieldInformationDisplay = ({ children, label, variants }) => (
  <label className={classNames("form-field-information-display", variants)}>
    { label }
    { children }
  </label>
);

FormFieldInformationDisplay.propTypes = {
  children: PropTypes.node,
  label: PropTypes.string.isRequired,
  variants: PropTypes.string
};

export default FormFieldInformationDisplay;
