import PropTypes from "prop-types";
import React from "react";

import { inject, observer } from "mobx-react";

import ResourceView from "../components/ResourceView.js";
import CorrectnessDetail from "../components/CorrectnessDetail.js";

class CorrectnessDetailContainer extends React.Component {
  static propTypes = {
    wordStore: PropTypes.object
  };

  render() {
    const {
      wordStore
    } = this.props;
    const word = wordStore.word.value();

    return (
      <CorrectnessDetail word={word} />
    );
  }
}

export default inject("wordStore")(ResourceView((props) => props.wordStore.word)(observer(CorrectnessDetailContainer)));
