import React from "react";
import { propTypes as MobxPropTypes } from "mobx-react";

import routes from "../routes.js";

import CorrectnessWordDetail from "./CorrectnessWordDetail.js";

const OrganizationNameDetail = (props) =>
  <CorrectnessWordDetail {...props}
    newPath={routes.get("newOrganizationName")}
    editPath={routes.get("editOrganizationName", { wordId: props.word.id })} />;

OrganizationNameDetail.propTypes = {
  word: MobxPropTypes.observableObject.isRequired
};

export default OrganizationNameDetail;
