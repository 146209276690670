import PropTypes from "prop-types";
import React from "react";

class InputField extends React.Component {
  static propTypes = {
    title: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func
  };

  handleChange = (event) => {
    this.props.onChange(event.target.name, event.target.value);
  };

  render() {
    const {
      title,
      name,
      value
    } = this.props;

    if (title) {
      return (
        <div>
          <div className="label-block">{title}</div>
          <input type="text" className="input-block" name={name} value={value} onChange={this.handleChange} />
        </div>
      );
    } else {
      return (
        <input type="text" className="input-block" name={name} value={value} onChange={this.handleChange} />
      );
    }
  }
}

export default InputField;
