import PropTypes from "prop-types";
import React from "react";
import { inject, observer } from "mobx-react";

import ResourceView from "../components/ResourceView.js";
import OrganizationNamesSearchBlock from "../components/OrganizationNamesSearchBlock.js";

import history from "../history";
import routes from "../routes.js";

class OrganizationNamesSearchBlockContainer extends React.Component {
  static propTypes = {
    location: PropTypes.object,
    categoriesStore: PropTypes.object
  };

  handleSearch = (searchProps) => {
    history.push({
      pathname: routes.get("organizationNames"),
      query: searchProps
    });
  };

  render() {
    const {
      categoriesStore
    } = this.props;
    const {
      categories
    } = categoriesStore.categories.value();

    return (
      <OrganizationNamesSearchBlock onSearch={this.handleSearch}
        categories={categories}
        {...this.props.location.query} />
    );
  }
}

export default inject("categoriesStore")(ResourceView((props) => props.categoriesStore.categories)(observer(OrganizationNamesSearchBlockContainer)));
