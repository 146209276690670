import { observable, computed } from "mobx";
import { serializable, identifier, createSimpleSchema, object } from "serializr";

const opinionsSchema = createSimpleSchema({
  isAllowed: true,
  isNotAllowed: true,
  isActive: true
});

class Alternative {
  @serializable(identifier()) id;
  @observable @serializable name = "";
  @observable @serializable isAllowed;
  @observable @serializable isActive;
  @observable @serializable(object(opinionsSchema)) opinions = {
    isAllowed: 0,
    isNotAllowed: 0,
    isActive: 0
  };

  @computed get isSimple() {
    return this.isAllowed === true;
  }

  @computed get isDifficult() {
    return this.isAllowed === false;
  }

  @computed get isCurrentlyActive() {
    return this.isActive === true;
  }
}

export default Alternative;
