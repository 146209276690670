import PropTypes from "prop-types";
import React from "react";

import DivFactory from "guc/components/DivFactory";

const TableHeaderDiv = DivFactory({
  displayName: "TableHeader",
  cssClass: "guc-table-header"
});

const Header = DivFactory({
  displayName: "Header",
  cssClass: "header"
});

const TableHeader = (props) => {
  const {
    fields,
    headerOptions,
    ...other
  } = props;

  return (
    <TableHeaderDiv {...other}>
      {fields.map((field, i) =>
        <Header {...headerOptions} key={i}>
          {field}
        </Header>
      )}
    </TableHeaderDiv>
  );
};

TableHeader.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.node),
  headerOptions: PropTypes.object
};

export default TableHeader;
