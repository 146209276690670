import WordsTransport from "../transports/WordsTransport.js";
import CategoriesTransport from "../transports/CategoriesTransport.js";

import ViewStore from "./ViewStore.js";
import WordStore from "./WordStore.js";
import WordsStore from "./WordsStore.js";
import CategoriesStore from "./CategoriesStore.js";

const wordsTransport = new WordsTransport();
const categoriesTransport = new CategoriesTransport();

const wordsStore = new WordsStore(wordsTransport);
const wordStore = new WordStore(wordsTransport);
const categoriesStore = new CategoriesStore(categoriesTransport);

const viewStore = new ViewStore(wordsStore, wordStore, categoriesStore);

window.wordsStore = wordsStore;
window.wordStore = wordStore;

export default {
  viewStore,
  wordsStore,
  wordStore,
  categoriesStore
};
