import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router";
import { PropTypes as MobxPropTypes } from "mobx-react";
import I18n from "i18n-js";

import ContentContainer from "guc/components/ContentContainer";
import TitleBlock from "guc/components/TitleBlock";

import PaginationBlock from "./PaginationBlock.js";
import WordsTable from "./WordsTable.js";
import ListDisplay from "./ListDisplay.js";

import InfiniteScrollList from "./InfiniteScrollList.js";

import routes from "../routes.js";

class WordsList extends React.Component {
  static propTypes = {
    title: PropTypes.string,
    words: MobxPropTypes.observableArray,
    onApprove: PropTypes.func,
    onDecline: PropTypes.func,
    onLoadMore: PropTypes.func,
    pagination: PropTypes.shape({
      page: PropTypes.number,
      totalPages: PropTypes.number,
      nextStart: PropTypes.string
    }),
    paginationLinkProps: PropTypes.object
  };

  render() {
    const mainDiv = document.getElementById("main");
    const featureCsvExport = mainDiv.dataset.featureCsv === "true";

    return (
      <div className="words-list">
        <ContentContainer>
          <TitleBlock>
            {this.props.title}
            <span>
              <Link to={routes.get("newDifficultWord")} title={I18n.t("actions.titles.newDifficultWord")}>
                <div className="guc-add-action" />
              </Link>
              {featureCsvExport &&
                <a href="/difficult_words/export">
                  <div className="icon guc-export-action fa-download fa" />
                </a>}
            </span>
          </TitleBlock>

          <ListDisplay hasData={this.props.words.length > 0}>
            <div>
              <InfiniteScrollList onLoadMore={this.props.onLoadMore} hasMore={this.props.pagination.nextStart != null}>
                <WordsTable words={this.props.words}
                  onApprove={this.props.onApprove}
                  onDecline={this.props.onDecline} />
              </InfiniteScrollList>

              <PaginationBlock {...this.props.pagination} linkProps={this.props.paginationLinkProps} />

            </div>
          </ListDisplay>
        </ContentContainer>
      </div>
    );
  }
}

export default WordsList;
