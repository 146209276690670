import PropTypes from "prop-types";
import React from "react";
import I18n from "i18n-js";

import { propTypes } from "mobx-react";

import TitleBlock from "guc/components/TitleBlock";

import TableBlock from "guc/components/TableBlock";
import TableHeader from "guc/components/TableHeader";
import TableContent from "guc/components/TableContent";
import TableRow from "guc/components/TableRow";
import TableField from "guc/components/TableField";

import ListDisplay from "./ListDisplay.js";

class AlternativeRow extends React.Component {
  static propTypes = {
    alternative: PropTypes.shape({
      name: PropTypes.string.isRequired
    }).isRequired
  };

  render() {
    return (
      <TableRow>
        <TableField>
          {this.props.alternative.name}
        </TableField>
      </TableRow>
    );
  }
}

class AlternativesList extends React.Component {
  static propTypes = {
    title: PropTypes.string,
    listTitle: PropTypes.string,
    alternatives: propTypes.arrayOrObservableArray.isRequired
  };

  render() {
    return (
      <div>
        <TitleBlock>{this.props.title || I18n.t("difficult_words.alternatives.title")}</TitleBlock>
        <div className="content">
          <ListDisplay hasData={this.props.alternatives.length > 0}>
            <TableBlock>
              <TableHeader fields={[
                this.props.listTitle || I18n.t("difficult_words.alternatives.alternative")
              ]} />
              <TableContent>
                {this.props.alternatives.map((alternative) => <AlternativeRow key={alternative.id} alternative={alternative} />)}
              </TableContent>
            </TableBlock>
          </ListDisplay>
        </div>
      </div>
    );
  }
}

export default AlternativesList;
