import PropTypes from "prop-types";
import React from "react";

const TooltipIcon = (props) => (
  <span className="tooltip-icon" title={props.tooltip} />
);

TooltipIcon.propTypes ={
  tooltip: PropTypes.string
};

export default TooltipIcon;
