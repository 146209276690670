import PropTypes from "prop-types";
import React from "react";

class SidebarContent extends React.Component {
  static propTypes = {
    children: PropTypes.node
  };

  render() {
    return (
      <div className="sidebar-content">
        {this.props.children}
      </div>
    );
  }
}

export default SidebarContent;
