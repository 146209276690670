import PropTypes from "prop-types";
import React from "react";
import { inject, observer } from "mobx-react";

import { correctnessWordsFilterFromLocation } from "../stores/ViewStore.js";

import ResourceView from "../components/ResourceView.js";
import CorrectnessList from "../components/CorrectnessList.js";

const CorrectnessListObserver = observer(CorrectnessList);

class CorrectnessWordsList extends React.Component {
  static propTypes = {
    wordsStore: PropTypes.object,
    location: PropTypes.object
  };

  handleDecline = (word) => {
    this.props.wordsStore.updateIsAllowed(word, "false");
  };

  handleApprove = (word) => {
    this.props.wordsStore.updateIsAllowed(word, "true");
  };

  handleLoadMore = () => {
    const filter = correctnessWordsFilterFromLocation(this.props.location);
    return this.props.wordsStore.loadMore(filter);
  };

  generatePaginationLink = (page) => {
    const {
      pathname,
      query
    } = this.props.location;

    return {
      pathname,
      query: {
        ...query,
        page: page
      }
    };
  };

  render() {
    const {
      wordsStore
    } = this.props;
    const {
      words,
      pagination
    } = wordsStore.words.value();

    return (
      <CorrectnessListObserver words={words}
        pagination={pagination}
        paginationLinkProps={{generateLink: this.generatePaginationLink}}
        onApprove={this.handleApprove}
        onDecline={this.handleDecline}
        onLoadMore={this.handleLoadMore} />
    );
  }
}

export default inject("wordsStore")(ResourceView((props) => props.wordsStore.words)(observer(CorrectnessWordsList)));
