import PropTypes from "prop-types";
import React from "react";

import TableRow from "guc/components/TableRow";
import TableField from "guc/components/TableField";

import OpinionsCircles from "./OpinionsCircles.js";

class OpinionsList extends React.Component {
  static propTypes = {
    fields: PropTypes.arrayOf(
      PropTypes.node
    ).isRequired,
    actions: PropTypes.arrayOf(
      PropTypes.node
    ).isRequired,
    destroyAction: PropTypes.node
  };

  render() {
    const {
      variants,
      fields,
      actions,
      destroyAction,
      ...other
    } = this.props;

    return (
      <TableRow variants={variants}>
        { fields.map((field, i) =>
          <TableField key={i}>{field}</TableField>
        )}
        <TableField variants="-actions">
          { destroyAction }
          <OpinionsCircles {...other} />
          { actions.map((action, i) =>
            <span key={i}>{action}</span>
          )}
        </TableField>
      </TableRow>
    );
  }
}

export default OpinionsList;
