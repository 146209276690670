import PropTypes from "prop-types";
import React from "react";

const TwoColumns = function({children}) {
  return (
    <div className="two-columns">
      {children}
    </div>
  );
};

TwoColumns.propTypes = {
  children: PropTypes.node
};

export default TwoColumns;
