import PropTypes from "prop-types";
import React from "react";

import I18n from "i18n-js";

const ListDisplay = (props) => {
  const {
    hasData,
    children
  } = props;

  if (hasData) {
    return children;
  } else {
    return <span>{I18n.t(`components.ListDisplay.no_results`)}</span>;
  }
};

ListDisplay.propTypes = {
  hasData: PropTypes.bool.isRequired,
  children: PropTypes.node
};

export default ListDisplay;
