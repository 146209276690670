import PropTypes from "prop-types";
import React from "react";

import PaginationLink from "./PaginationLink.js";
import PaginationFactory from "./PaginationFactory";

class PaginationBlock extends React.Component {
  static propTypes = {
    totalPages: PropTypes.number,
    page: PropTypes.number,
    window: PropTypes.number
  };

  render() {
    const Pagination = PaginationFactory(PaginationLink);
    return (
      <Pagination {...this.props} />
    );
  }
}

export default PaginationBlock;
