import PropTypes from "prop-types";
import React from "react";
import { PropTypes as MobxPropTypes } from "mobx-react";

import ContentContainer from "guc/components/ContentContainer";
import TitleBlock from "guc/components/TitleBlock";

import routes from "../routes.js";

import WordForm from "./WordForm.js";

export default class extends React.Component {
  static propTypes = {
    word: MobxPropTypes.observableObject.isRequired,
    onSave: PropTypes.func
  };

  render() {
    const word = this.props.word;
    const cancelPath = routes.get("showDifficultWord", {wordId: word.id});

    return (
      <div className="word-detail">
        <ContentContainer>
          <TitleBlock>
            {word.name}
          </TitleBlock>

          <WordForm onSave={this.props.onSave}
            word={word}
            cancelPath={cancelPath} />
        </ContentContainer>
      </div>
    );
  }
}
