import PropTypes from "prop-types";
import React from "react";

import ContentContainer from "guc/components/ContentContainer";
import TitleBlock from "guc/components/TitleBlock";

class ErrorComponent extends React.Component {
  static propTypes = {
    error: PropTypes.shape({
      title: PropTypes.string,
      message: PropTypes.string,
      requestId: PropTypes.string
    }).isRequired
  };

  render() {
    const {
      title,
      message,
      requestId
    } = this.props.error;

    return (
      <ContentContainer>
        <TitleBlock>{title}</TitleBlock>
        <div>{message}</div>
        {requestId &&
          <div>Verzoekcode: {requestId}</div>}
      </ContentContainer>
    );
  }
}

export default ErrorComponent;
